<template>
  <div class="document-layout">
    <PublicDocument />
  </div>
</template>

<script>
// Components
import PublicDocument from '@/components/document/PublicDocument'

export default {
  name: 'DocumentLayout',

  components: {
    PublicDocument
  }
}
</script>
