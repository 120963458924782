const isLanguageDirectionRTL = languageCode => {
  return (
    languageCode === 'ar' ||
    languageCode === 'he' ||
    languageCode === 'fa' ||
    languageCode === 'ur'
  )
}

export default isLanguageDirectionRTL
