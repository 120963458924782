<template>
  <main
    v-if="getPublicDocument"
    :class="[
      'document',
      {
        'document--serif': getPublicDocument.serif,
        'document--expanded': getPublicDocument.expanded
      }
    ]"
  >
    <header class="document__header">
      <img
        @click="$router.replace('/signup')"
        class="document__header-logo"
        src="../../assets/images/logo-monogram.svg"
        alt="Logo"
      />

      <!-- @TODO - Add when ready -->
      <!-- <router-link
        v-if="$user.uid"
        class="document__header-signup-link"
        :to="`/document/${getPublicDocument.uid}`"
      >
        Back to edit mode
      </router-link> -->
      <a
        v-if="!$user.uid"
        class="document__header-signup-link"
        :href="
          `https://fluently.so/?${mode}?creator=${getPublicDocument.creatorUid}?source=${getPublicDocument.uid}?document=${getPublicDocument.publicUid}`
        "
        target="_blank"
      >
        {{ $languages[getAppLanguage].global.notifications.tryItOut }}
      </a>

      <div
        @click="toggleDarkMode"
        :class="[
          'document__header__dark-mode-button',
          { 'document__header__dark-mode-button--active': getDarkMode }
        ]"
      >
        <i class="icon-dark-mode"></i>
      </div>
    </header>

    <section v-if="getPublicDocument.text" class="document__view-wrapper">
      <section class="document__view">
        <div
          v-html="getPublicDocument.text"
          :class="[
            'document__view-text',
            { 'document__view-text--rtl': isTextRTL }
          ]"
        ></div>

        <footer class="document__footer document__footer--text">
          <span>
            {{ $wordCounter(getPublicDocument.text).characters }}
            {{ $languages[getAppLanguage].editor.footer.characters }},
            {{ $wordCounter(getPublicDocument.text).words }}
            {{ $languages[getAppLanguage].editor.footer.words }},
            {{
              $readingTimeCalculator(getPublicDocument.text)
                .totalHumanizedDuration
            }}
          </span>

          <span v-if="getPublicDocument.translationSourceLanguage">
            {{ getPublicDocument.translationSourceLanguage.language }}
            {{
              getPublicDocument.translationSourceLanguage.variation
                ? `(${getPublicDocument.translationSourceLanguage.variation})`
                : ''
            }}
          </span>
        </footer>
      </section>

      <section
        v-if="
          getPublicDocument.translation &&
            !getPublicDocument.expanded &&
            getPublicDocument.activeService === 'translator'
        "
        class="document__view"
      >
        <div
          v-html="getPublicDocument.translation"
          :class="[
            'document__view-translation',
            { 'document__view-translation--rtl': isTranslationRTL }
          ]"
        ></div>

        <footer class="document__footer document__footer--translation">
          <span>
            {{ $wordCounter(getPublicDocument.translation).characters }}
            characters,
            {{ $wordCounter(getPublicDocument.translation).words }}
            words,
            {{
              $readingTimeCalculator(getPublicDocument.translation)
                .totalHumanizedDuration
            }}
          </span>

          <span v-if="getPublicDocument.translationLanguage">
            {{ getPublicDocument.translationLanguage.language }}
            {{
              getPublicDocument.translationLanguage.variation
                ? `(${getPublicDocument.translationLanguage.variation})`
                : ''
            }}
          </span>
        </footer>
      </section>
    </section>
  </main>

  <main v-else class="document document--not-found">
    <header class="document__header">
      <img
        @click="$router.replace('/signup')"
        class="document__header-logo"
        src="../../assets/images/logo-monogram.svg"
        alt="Logo"
      />

      <div
        @click="toggleDarkMode"
        :class="[
          'document__header__dark-mode-button',
          { 'document__header__dark-mode-button--active': getDarkMode }
        ]"
      >
        <i class="icon-dark-mode"></i>
      </div>
    </header>

    <section class="document__view-wrapper">
      <section class="document__view">
        <div class="document__view-text">
          <h1>Oops!</h1>

          <br />

          <strong>
            <p>
              The document you are looking for does not exist or has been
              unpublished by the author.
            </p>
          </strong>

          <hr />

          <p>
            {{ $companyName }} is an online document editor with a built-in
            translator. You can create documents in a foreign language while
            writing in your mother tongue. Write and read in a foreign language
            easily and accurately, and make others understand you.
          </p>

          <br />
          <br />

          <Button
            @button-clicked="$router.replace('/signup')"
            icon="translate-main"
          >
            Start writing {{ $companyName }} now
          </Button>
        </div>

        <footer class="document__footer">
          <span>Write {{ $companyName }} in a foreign language.</span>

          <span
            >All rights reserved © {{ $companyName }}
            {{ new Date().getFullYear() }}.</span
          >
        </footer>
      </section>
    </section>
  </main>
</template>

<script>
// Vuex
import { mapGetters, mapActions } from 'vuex'

// Helpers
import isLanguageDirectionRTL from '@/helpers/isLanguageDirectionRTL'

// Components
import Button from '@/components/Button'

export default {
  name: 'Document',

  components: {
    Button
  },

  data: () => ({
    isTextRTL: false,
    isTranslationRTL: false,
    mode: null
  }),

  computed: {
    ...mapGetters(['getPublicDocument', 'getDarkMode', 'getAppLanguage'])
  },

  watch: {
    getPublicDocument() {
      if (this.getPublicDocument) {
        this.isTextRTL = isLanguageDirectionRTL(
          this.getPublicDocument.translationSourceLanguage.code
        )

        this.isTranslationRTL = isLanguageDirectionRTL(
          this.getPublicDocument.translationLanguage.code
        )
      }

      return this.getPublicDocument
    }
  },

  mounted() {
    this.fetchPublicDocumentsAction()
    this.mode = process.env.VUE_APP_ENV
  },

  methods: {
    ...mapActions(['fetchPublicDocumentsAction', 'setDarkMode']),

    /**
     *
     */
    toggleDarkMode() {
      this.$setDarkMode(!this.$getDarkMode())
      return this.setDarkMode(!this.getDarkMode)
    }
  }
}
</script>

<style lang="scss" scoped>
.document {
  width: 100vw;
  font-family: var(--font-family-default);

  &--expanded {
    .document__view-text {
      // height: calc(
      //   var(--vh, 1vh) * 100 - (#{$header-height} + #{$footer-height})
      // );
      height: calc(100vh - (#{$header-height} + #{$footer-height}));

      @include screen-large {
        padding: var(--size-unit-10) calc(50% - 360px);
      }
    }
  }

  @include screen-large {
    flex-direction: row;
  }

  &--not-found {
    .document__view-text {
      height: calc(100vh - (#{$header-height} + #{$footer-height}));

      @include screen-large {
        padding: var(--size-unit-10) calc(50% - 360px);
      }
    }
  }
}

.document__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $header-height;
  text-align: center;
  background-color: var(--color-brand-darkest);
}

.document__header-logo {
  cursor: pointer;
}

.document__header-signup-link {
  text-decoration: underline;
  text-align: center;
  font-size: var(--font-size-xs);
  color: var(--color-brand-lightest);
  transition: color var(--transition-default);
  cursor: pointer;

  &:hover {
    color: var(--color-main);
  }
}

.document__header__dark-mode-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--size-unit-5);
  height: inherit;
  color: var(--color-light);
  transition: color var(--transition-default);
  cursor: pointer;

  &:hover,
  &--active {
    color: var(--color-main);
  }

  &--active {
    color: var(--color-main);

    &:hover {
      color: var(--color-main-dark);
    }
  }
}

.document__view-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: var(--font-family-default);

  @include screen-large {
    flex-direction: row;
  }
}

.document__view {
  width: 100%;
  color: var(--color-dark);
}

.document__view-text,
.document__view-translation {
  overflow: scroll;
  padding: var(--size-unit-4) var(--size-unit-2) var(--size-unit-2);
  background-color: var(--color-theme-lightest);
  height: calc(50vh - (#{$footer-height} * 2) + (#{$footer-height} / 2));
  word-break: break-word;
  transition: var(--transition-default);

  &--rtl {
    @include rtl;
  }

  @include screen-small {
    padding: var(--size-unit-4) calc(50% - 290px);
  }

  @include screen-medium {
    padding: var(--size-unit-8) calc(50% - 320px);
  }

  @include screen-large {
    padding: var(--size-unit-10) var(--size-unit-4);
    height: calc(100vh - (#{$header-height} + #{$footer-height}));
  }

  @include screen-extra-large {
    padding: var(--size-unit-10) calc(50% - 360px);
  }
}

.document__view-translation {
  background-color: var(--color-theme-lighter);
}

.document__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $footer-height;
  padding: 0 var(--size-unit);
  font-size: var(--font-size-xs);
  color: var(--color-dark);
  transition: var(--transition-default);
  background-color: var(--color-theme-lightest);

  &--translation {
    background-color: var(--color-theme-lighter);
  }

  @include screen-small {
    padding: 0 var(--size-unit-2);
  }

  @include screen-medium {
    padding: 0 var(--size-unit-3);
  }

  @include screen-large {
    padding: 0 var(--size-unit-4);
  }
}
</style>
