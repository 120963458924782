var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.getPublicDocument)?_c('main',{class:[
    'document',
    {
      'document--serif': _vm.getPublicDocument.serif,
      'document--expanded': _vm.getPublicDocument.expanded
    }
  ]},[_c('header',{staticClass:"document__header"},[_c('img',{staticClass:"document__header-logo",attrs:{"src":require("../../assets/images/logo-monogram.svg"),"alt":"Logo"},on:{"click":function($event){return _vm.$router.replace('/signup')}}}),(!_vm.$user.uid)?_c('a',{staticClass:"document__header-signup-link",attrs:{"href":`https://fluently.so/?${_vm.mode}?creator=${_vm.getPublicDocument.creatorUid}?source=${_vm.getPublicDocument.uid}?document=${_vm.getPublicDocument.publicUid}`,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$languages[_vm.getAppLanguage].global.notifications.tryItOut)+" ")]):_vm._e(),_c('div',{class:[
        'document__header__dark-mode-button',
        { 'document__header__dark-mode-button--active': _vm.getDarkMode }
      ],on:{"click":_vm.toggleDarkMode}},[_c('i',{staticClass:"icon-dark-mode"})])]),(_vm.getPublicDocument.text)?_c('section',{staticClass:"document__view-wrapper"},[_c('section',{staticClass:"document__view"},[_c('div',{class:[
          'document__view-text',
          { 'document__view-text--rtl': _vm.isTextRTL }
        ],domProps:{"innerHTML":_vm._s(_vm.getPublicDocument.text)}}),_c('footer',{staticClass:"document__footer document__footer--text"},[_c('span',[_vm._v(" "+_vm._s(_vm.$wordCounter(_vm.getPublicDocument.text).characters)+" "+_vm._s(_vm.$languages[_vm.getAppLanguage].editor.footer.characters)+", "+_vm._s(_vm.$wordCounter(_vm.getPublicDocument.text).words)+" "+_vm._s(_vm.$languages[_vm.getAppLanguage].editor.footer.words)+", "+_vm._s(_vm.$readingTimeCalculator(_vm.getPublicDocument.text) .totalHumanizedDuration)+" ")]),(_vm.getPublicDocument.translationSourceLanguage)?_c('span',[_vm._v(" "+_vm._s(_vm.getPublicDocument.translationSourceLanguage.language)+" "+_vm._s(_vm.getPublicDocument.translationSourceLanguage.variation ? `(${_vm.getPublicDocument.translationSourceLanguage.variation})` : '')+" ")]):_vm._e()])]),(
        _vm.getPublicDocument.translation &&
          !_vm.getPublicDocument.expanded &&
          _vm.getPublicDocument.activeService === 'translator'
      )?_c('section',{staticClass:"document__view"},[_c('div',{class:[
          'document__view-translation',
          { 'document__view-translation--rtl': _vm.isTranslationRTL }
        ],domProps:{"innerHTML":_vm._s(_vm.getPublicDocument.translation)}}),_c('footer',{staticClass:"document__footer document__footer--translation"},[_c('span',[_vm._v(" "+_vm._s(_vm.$wordCounter(_vm.getPublicDocument.translation).characters)+" characters, "+_vm._s(_vm.$wordCounter(_vm.getPublicDocument.translation).words)+" words, "+_vm._s(_vm.$readingTimeCalculator(_vm.getPublicDocument.translation) .totalHumanizedDuration)+" ")]),(_vm.getPublicDocument.translationLanguage)?_c('span',[_vm._v(" "+_vm._s(_vm.getPublicDocument.translationLanguage.language)+" "+_vm._s(_vm.getPublicDocument.translationLanguage.variation ? `(${_vm.getPublicDocument.translationLanguage.variation})` : '')+" ")]):_vm._e()])]):_vm._e()]):_vm._e()]):_c('main',{staticClass:"document document--not-found"},[_c('header',{staticClass:"document__header"},[_c('img',{staticClass:"document__header-logo",attrs:{"src":require("../../assets/images/logo-monogram.svg"),"alt":"Logo"},on:{"click":function($event){return _vm.$router.replace('/signup')}}}),_c('div',{class:[
        'document__header__dark-mode-button',
        { 'document__header__dark-mode-button--active': _vm.getDarkMode }
      ],on:{"click":_vm.toggleDarkMode}},[_c('i',{staticClass:"icon-dark-mode"})])]),_c('section',{staticClass:"document__view-wrapper"},[_c('section',{staticClass:"document__view"},[_c('div',{staticClass:"document__view-text"},[_c('h1',[_vm._v("Oops!")]),_c('br'),_vm._m(0),_c('hr'),_c('p',[_vm._v(" "+_vm._s(_vm.$companyName)+" is an online document editor with a built-in translator. You can create documents in a foreign language while writing in your mother tongue. Write and read in a foreign language easily and accurately, and make others understand you. ")]),_c('br'),_c('br'),_c('Button',{attrs:{"icon":"translate-main"},on:{"button-clicked":function($event){return _vm.$router.replace('/signup')}}},[_vm._v(" Start writing "+_vm._s(_vm.$companyName)+" now ")])],1),_c('footer',{staticClass:"document__footer"},[_c('span',[_vm._v("Write "+_vm._s(_vm.$companyName)+" in a foreign language.")]),_c('span',[_vm._v("All rights reserved © "+_vm._s(_vm.$companyName)+" "+_vm._s(new Date().getFullYear())+".")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('strong',[_c('p',[_vm._v(" The document you are looking for does not exist or has been unpublished by the author. ")])])
}]

export { render, staticRenderFns }